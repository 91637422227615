import { useState, useCallback } from 'react';

import { Modal, TextField, Button, Spinner } from '@shopify/polaris';

import { ApiError, Section, Variation, useFetchJson } from '../../api';
import { handleKeyPress } from '../../common/utils';
import { useShopStatus } from '../../graph';

type CreateVariationModalProps = {
  template: string;
  section: Section;
  copy: Variation | undefined;
  active: boolean;
  setActive: (active: boolean) => void;
  updateVariations: () => Promise<void>;
};

export default function CreateVariationModal({
  template,
  section,
  copy,
  active,
  setActive,
  updateVariations,
}: CreateVariationModalProps): JSX.Element {
  const { myshopifyDomain } = useShopStatus();
  const jsonFetch = useFetchJson();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(undefined as string | undefined);
  const [input, setInput] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = useCallback(() => {
    async function submit() {
      if (input === '') {
        setError('Variation name is required');
        return;
      }
      setError('');
      setLoading(true);

      try {
        const result: Record<string, any> = await jsonFetch(
          'put',
          `/variations`,
          {
            name: input,
            type: section.type,
            template,
            // CTX-1168: track variations created for a specific section
            sectionId: section.id,
            block_order: copy ? copy.block_order : section.block_order,
            blocks: copy ? copy.blocks : section.blocks,
            settings: copy ? copy.settings : section.settings,
            copyFromId: copy?.template ? copy.id : undefined,
          },
        );
        setId(result.id);
        updateVariations();
      } catch (err) {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new Error("can't create variation");
        }
      } finally {
        setLoading(false);
      }
    }

    submit();
  }, [input, jsonFetch, template, section, updateVariations, copy]);

  const close = useCallback(() => {
    setActive(false);
    setLoading(false);
    setId(undefined);
    setInput('');
    setError('');
  }, [setActive]);

  const handleInputChange = useCallback((value: string) => {
    setInput(value);
  }, []);

  let title = 'Create variation';
  if (loading) {
    title = 'Creating variation';
  } else if (id) {
    title = 'Edit variation';
  }

  return (
    <Modal
      open={active}
      onClose={close}
      title={title}
      primaryAction={
        loading || id
          ? undefined
          : {
              content: 'Create variation',
              onAction: handleSubmit,
            }
      }
      secondaryActions={
        loading || id
          ? undefined
          : [
              {
                content: 'Cancel',
                onAction: close,
              },
            ]
      }
    >
      <Modal.Section>
        {loading && (
          <div className="rb-flex rb-flex-col rb-gap-4 rb-my-8">
            <div className="rb-flex rb-justify-center">
              <Spinner />
            </div>
          </div>
        )}
        {!loading && id && (
          <div className="rb-flex rb-flex-col rb-gap-4 rb-my-8">
            <div className="rb-flex rb-justify-center">
              <p>
                <strong>{input}</strong> has been created.
              </p>
            </div>
            <div className="rb-flex rb-justify-center">
              <Button
                primary
                onClick={() => {
                  window.open(
                    `/api/admin/shopify/edit/?shop=${myshopifyDomain}&variation=${encodeURIComponent(
                      id,
                    )}`,
                    '_blank',
                  );
                  close();
                }}
              >
                Open in editor
              </Button>
            </div>
          </div>
        )}
        {!loading && !id && (
          <div
            role="textbox"
            tabIndex={0}
            onKeyDown={event => handleKeyPress(event, handleSubmit)}
          >
            <TextField
              label="Variation name *"
              value={input}
              onChange={handleInputChange}
              error={error}
              placeholder={copy?.name || 'Enter a unique variation name'}
              helpText="e.g. 15% off running gear promotion"
              autoComplete="off"
            />
          </div>
        )}
      </Modal.Section>
    </Modal>
  );
}
